import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';
import Header from '@/globals/Header';
import Modal from '@/globals/Modal';
import SetVh from '@/globals/SetVh';
import FadeIn from '@/globals/FadeIn';

import '@/plugins/logger';
import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';
import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';

webfontloader();

// load from data-behaviours
const app = loadApp(name => import(`./behaviours/${name}.js`), document.body); // eslint-disable-line

loadModule({
	module: Header,
	id: 'header',
	node: document.getElementById('header'),
	keepAlive: true,
});

loadModule({
	module: Modal,
	id: 'modal',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: SetVh,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: FadeIn,
	id: 'fadein',
	node: document.body,
	keepAlive: true,
});

detectTab();
activeLinks(window.location.pathname);
localLinks();

// Fade during loading. Dont need to remove as dom is updated
// https://htmx.org/events
if (typeof htmx !== 'undefined') {
	// eslint-disable-next-line
	htmx.on('htmx:beforeSend', ({ target }) => {
		const results = document.getElementById('results');
		if (results) {
			results.classList.add('is-loading');
		}
	});

	// Scroll to top of `sprig` container when interacting; but only when `scroll` is in Param (eg not filtering; but probably pagination)
	// eslint-disable-next-line
	htmx.on(
		'htmx:beforeSwap',
		({
			detail: {
				requestConfig: { triggeringEvent },
			},
			target,
		}) => {
			localLinks(target);

			if (triggeringEvent) {
				const { srcElement } = triggeringEvent;
				if (!srcElement) return;

				const shouldScroll = srcElement.closest('.c-pagination');
				if (srcElement && shouldScroll) {
					window.scrollTo({
						top: target.getBoundingClientRect().top + window.scrollY - 100,
						behavior: 'smooth',
					});
				}
			}
		}
	);

	// eslint-disable-next-line
	htmx.on('htmx:afterSwap', ({ target }) => {
		const results = document.getElementById('results');
		if (results) {
			results.classList.remove('is-loading');
		}

		localLinks(target);
		// We need to fire behaviours inside dynamically loaded blocks
		loadApp(name => import(`./behaviours/${name}.js`), target);
	});
}

document.addEventListener('afterBlitzInjectAll', event => {
	loadModule({
		module: Header,
		id: 'header',
		node: document.getElementById('header'),
		keepAlive: false,
	});
});
