import { withPlugins, eventBus, domEvents, device } from '@spon/plugins';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';
import gsap from 'gsap';

function Header({ node, name, plugins: { addEvents, device } }) {
	if (!node) return;
	/*
	 *		Variables
	 */

	const breakpoint = 100;

	// Headroom
	const options = {
		offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	};

	const headroom = new Headroom(node, options);

	const $burgerBlob = node.querySelector('#burger-blob');
	const $menuBlob = node.querySelector('#menu-blob');

	eventBus.on('menu:open', () => headroom.freeze());
	eventBus.on('menu:close', () => headroom.unfreeze());

	// const headerBackground = node.querySelector('.c-header__dropdown');
	// const searchForm = node.querySelector('.c-header__search');

	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${windowTop * -1}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const nav = toggle({
		button: node.querySelector('.burger'),
		name,
		activeClass: 'is-active',
	});

	const $navItems = [...node.querySelectorAll('.js-menu-li')];
	const $address = node.querySelector('.js-menu-address');

	/*const $navItems = [...node.querySelectorAll('.js-menu-li')];
	 *		Functions
	 */

	function clickWhenOpenHandle({ target }) {
		if (target.id === 'header' || target.closest('#header')) return; // Continue if clicking within #Header
		nav.close();
	}

	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.remove('invisible');
		target.classList.add('overflow-auto', 'visible', 'is-open');

		// Control Burger Blob Animation
		$burgerBlob.classList.remove('translate-x-full', '-translate-y-full');
		$burgerBlob.classList.add('delay-1100');

		// Control Menu Blob Animation
		$menuBlob.classList.remove('menu-animate-out');
		$menuBlob.classList.add('menu-animate-in');

		gsap.fromTo(
			$navItems,
			{
				opacity: 0,
				x: 80,
			},
			{
				opacity: 1,
				x: 0,
				duration: 0.5,
				stagger: 0.1,
				ease: 'easeOut',
				delay: 0.5,
			}
		);

		gsap.fromTo(
			$address,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				delay: 1,
			}
		);

		eventBus.emit('menu:open');

		lock.capture();

		document.addEventListener('click', clickWhenOpenHandle);
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.add('invisible');
		target.classList.remove('overflow-hidden', 'visible');
		target.classList.remove('is-open');

		// Control Burger Blob Animation
		$burgerBlob.classList.add('translate-x-full', '-translate-y-full');
		$burgerBlob.classList.remove('delay-1100');

		// Control Menu Blob Animation
		$menuBlob.classList.add('menu-animate-out');
		$menuBlob.classList.remove('menu-animate-in');

		gsap.set($navItems, {
			opacity: 0,
			x: 80,
		});

		eventBus.emit('menu:close');

		lock.release();

		document.removeEventListener('click', clickWhenOpenHandle);
	});

	eventBus.on('page:exited', nav.close);
	// Main Mobile Menu END

	function onKeyDown(event) {
		const { key } = event;
		if (key === 'Escape') {
			if (nav.isOpen) {
				nav.close();
			}
		}
	}

	function onPrimaryClick(event) {
		if (window.innerWidth < breakpoint) return;
		event.preventDefault();

		// if open; close.. vise versa
		if (event.target.classList.contains('collapsed')) {
			headerBackground.style.height = '';
		} else {
			if (window.innerWidth >= breakpoint) {
				// animate BG height
				const acc = event.target.nextElementSibling ?? null;
				if (!acc) return;

				const { top } = acc.getBoundingClientRect();
				const height = acc.scrollHeight;

				headerBackground.style.height = `${headerBackground.offsetHeight}px`;
				headerBackground.style.height = `${top + height}px`;
			}
		}
	}

	/*
	 *		Init
	 */
	nav.init();
	headroom.init();

	/*
	 *		Events
	 */

	addEvents({
		'click [data-nav-primary]': onPrimaryClick,
	});

	addEvents(document.body, {
		click: ({ target }) => {
			if (target.id === 'header' || target.closest('#header')) return;
		},
		keydown: onKeyDown,
	});

	return () => {
		navAccordions.forEach(item => {
			item.dispose();
		});
	};
}

export default withPlugins(domEvents, device)(Header);
